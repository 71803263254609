/* 
==============================================
CONTENT:	Project Name Here
AUTHOR:		Your Name Here
==============================================

TABLE OF CONTENTS:

0. Reset
1. Main Layout
2. Content

==============================================
*/

/* 0. Reset ================================= */
@import 'reset';
@import url("https://use.typekit.net/wyk8wvv.css");
@import 'variables';


/* 1. Main Layout ================================= */

* {
	box-sizing: border-box;
	
}

body {
	background-image: url(../img/mobilebg.jpg);
	background-size: contain;
	overflow-x: hidden;
	
	@include desktop{
		background-repeat: no-repeat;
		background-image: url(../img/img212.jpg);
		background-size: cover;
		background-attachment: fixed;
	}
}

.wrapper{
	margin: auto;
	width: 90%;
	@include desktop{
		max-width: 80%;
	}
	@include bg-desktop{
		max-width: 1024px;
	}
}

.nav-thing{
	height: 100px;
}

video{
	width: 10px;
	height: 10px;
	visibility: hidden;
}




/* 2. Content ================================= */

nav{
	bottom: 0;
	position: fixed;
	width: 100%;
	z-index: 100;
	background: #001935;

	@include desktop{
		background: -moz-linear-gradient(left, #001935 60%, rgba(0,0,0,0) 90%);
		background: -webkit-linear-gradient(left, #001935 60%,rgba(0,0,0,0) 90%);
		background: linear-gradient(to right, #001935 60%,rgba(0,0,0,0) 90%);
	}
	
	ul{
		display: flex;
		width: 100%;
		justify-content: space-around;
		align-items: center;
		padding: 10px 0px;

		@include desktop{
			width: 70%;
			padding: 0px;
		}

		li{
			padding: 2%;
		}
		

		a{
			@include nav-font;
			color: $link-color;
			font-size: 1.5rem;
			text-decoration: none;
			padding: 3%;
			transition: 0.2s;

			&:hover{
				color: #001935;
				text-shadow:
					1px 1px 0 $link-color,
					-1px -1px 0 $link-color,  
					1px -1px 0 $link-color,
					-1px 1px 0 $link-color,
					1px 1px 0 $link-color;
				transition: 0.2s;
			}

			@include tablet{
				font-size: 2rem;
				
			}
		}
	}
}

.backgroundCanvas{
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	transform-origin: bottom;
	position: fixed;
	z-index: -1;
	margin: 0 auto;
}

.pixi{
	img{
		width: 100%;

		@include tablet{
			display: block;
			width: 50%;
			margin: 10% auto;
		}

		@include desktop{
			display: none;
		}
	}
}

#mobileCanvas{
	display: block;

	@include desktop{
		display: none;
	}
}

#desktopCanvas{
	display: none;

	@include desktop{
		display: block;
	}
}

.intro{
	position: relative;
	margin: 0 auto;
	margin-bottom: 2%;

	@include tablet{
		margin: -15% auto;
		@include header-color;
	}

	@include desktop{
		margin: 35% auto;
		margin-bottom: 5%;
	}
}

h1{
	@include bold-serif;
	font-size: 4rem;
	text-align: center;
	// margin: 30%;
	// margin-bottom: 0%;

	@include desktop{
		font-size: 7rem;
	}
}

.landing-page{
	@include light-sans;
	font-size: 2rem;
	text-align: center;
	display: block;
	width: 60%;
	margin: 0 auto;

	@include tablet{
		width: 100%;
	}
	
	@include desktop{
		font-size: 4rem;
	}
}

//WORK

h2{
	@include bold-serif;
	font-size: 3rem;
	padding-top: 10%;
	
	@include tablet{
		@include header-color;
	}
}

.works { // a UL
	@include tablet{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	li {
		width:	100%;
		margin-bottom: 20%;

		@include tablet{
			flex-basis: 45%;
			min-width: 45%;
			margin-bottom: 15%;
		}

		@include bg-desktop{
			flex-basis: 43%;
			min-width: 43%;
		}

		.border{
			background-image: url(../img/workbg2.png);
			background-size: cover;
			background-position-x: 30%;
			transition: 1s;

			&:hover{
				animation: ani-background 1s infinite;
				// background-size: 160%; 
			}

			&.even{
				background-image: url(../img/workbg1.png);
				background-size: contain;
				background-repeat: no-repeat;
				background-position-y: 0%;

				&:hover{
					animation: ani-background-even 1s infinite;
					// background-size: 160%; 
				}
			}
		}

		@keyframes ani-background{
			
			10%{
				background-position-x: 35%;
			}
			30%{
				background-position-x: 30%;
			}
			60%{
				background-position-x: 25%;
			}
		}

		@keyframes ani-background-even{
			
			10%{
				background-position-y: -5%;
			}
			30%{
				background-position-y: -2%;
			}
			60%{
				background-position-y: -4%;
			}
		}




		.description{
			// @include description-color;
			position: relative;
			// transform: translate(0px,90px);
			padding: 10% 0 5% 15%;
			width: 100%;
			text-align: left;
			z-index: 2;

			@include desktop{
				// // transform: translate(10%,20%);
				// padding-left: 5%;
				text-align: left;
			}

			h3{
				@include regular-sans;
				font-size: 1.75rem;
				padding: 20px 0px;
				color: white;
				
			}
		
			.category{
				@include light-sans;
				font-size: 1.5rem;
				padding: 5px 0px;
				display: inline;
				color: white;
			}

			.disabled-mobile{
				pointer-events: none;

				@include desktop{
					pointer-events: auto;
				}
			}

		}

		.buttons{
			display: flex;
			justify-content: space-around;
			padding: 25px;
			bottom: 0;
			align-content: flex-end;
			
			a{
				background-image: linear-gradient(to right, #C1DCEB 0%, #3B5D7C 51%, #C1DCEB 100%);
				@include light-sans;
				padding: 15px 35px;
				color: white;
				text-decoration: none;
				text-transform: uppercase;
				font-size: 20px;
				transition: 0.5s;
				border: 1px solid #546D8C;
				background-size: 200% auto;
			
				&:hover {
					background-position: right center;
					transition: 0.5s;
					color: #112241;
					border: 1px solid #112241;
					font-weight: 500;
				}
			}
		}
			

		.work-wrapper{
			width: 100%;

			img{
				width: 100%;
				filter: grayscale(100%);
				transition: 0.5s;

				&:hover{
					filter:grayscale(0%);
					transition: 0.5s;
				}
			}
		}
	}


}
// About

#about{
	@include tablet{
		display: flex;
		flex-direction: column;
		position: relative;
	}


	.border{
		width: 100%;
		height: 0;
		padding-bottom: 25%;
		position: absolute;
		pointer-events: none;
		display: block;
		background-repeat: no-repeat;
		background-position: right bottom;
		background-size: contain;
		display: none;
		
		&.bottom-border{
			background-image: url(../img/work-border-one.png);
			bottom: -3%;
			right: -3%;
			transform: rotate(-5deg);

			@include tablet{
				display: block;
			}
		}

		&.top-border{
			background-image: url(../img/work-border-two.png);
			
			@include desktop{
				display: block;
				bottom: 5%;
				left: -73%;
				transform: rotate(10deg);
			}
		}
	}
}

.second-row{
	display: flex;
	flex-direction: row;
	margin-top: 50px;
}

.third-row{
	padding-bottom: 5%;

	@include tablet{
		display: flex;
		justify-content: space-between;
	}
	li{
		padding-bottom: 5%;
	}
}

.header{
	width: 100%;
	text-align: center;
	
	@include tablet{
		@include header-color;
	}

	h2{
		padding: 20px 0px;
	}
}

.pic{
	width: 100%;
	margin-bottom: 25px;

	@include tablet{
		margin-right: 2%;
	}

	img{
		width: 100%;
		border: 10px solid rgba(156,190,190,0.8);
		
	}

}

.logo{
	width: 50%;
	margin-bottom: 25px;
	align-self: center;

	@include tablet{
		flex-basis: 50%;
	}

	// @include desktop{
	// 	flex-basis: 20%;
	// }

	img{
		width: 100%;
	}
}

.five-w{
	@include description-color;
	padding: 5%;
	margin-bottom: 25px;
	
	
	@include tablet{
		margin-bottom: 0px;
	}
	
	@include desktop{
		border-radius: 26% 74% 31% 69% / 62% 33% 67% 38%;
		margin-right:1%;
	}

	li{
		padding-bottom: 4%;

		&:last-child{
			padding-bottom: 0;
		}
	}

	h3{
		@include regular-sans;
		text-decoration: underline;
		text-transform: uppercase;
		font-size: 20px;
		padding-bottom: 5px;
	}

	.five-w-answers{
		@include light-sans;
		font-size: 20px;
		padding-bottom: 20px;
	}
}

.blurb{
	@include description-color;
	padding: 5%;

	@include tablet{
		width: 62%;
	}
	@include desktop{
		width: 70%;
	}

	p{
		@include light-sans;
		font-size: 18px;
		line-height: 1.25em;
		margin-bottom: 0.75em;

		@include tablet{
			font-size: 22px;
		}
	}

	.strike{
		text-decoration: line-through;

	}

	a{
		@include regular-sans;
		color: #264553;
		background: linear-gradient(to bottom, #3B5D7C 0%,#546D8C 100%);
		background-position: 0 100%;
		background-repeat: repeat-x;
		background-size: 4px 2px;
		text-decoration: none;
		transition: .5s;
		cursor: pointer;

		&:hover {
			background-size: 4px 50px;
			color: white;
			transition: 0.5s;
		}
	}
}

.contact-container{
	@include description-color;
	padding: 5%;
	border-radius: 5%;

	@include tablet{
		display: flex;
	}

	h3{
		@include regular-sans;
		font-size: 2rem;
		padding-top: 3%;

		@include tablet{
			font-size: 3rem;
		}
	}

	a{
		color: #264553;
		background: linear-gradient(to bottom, #3B5D7C 0%,#546D8C 100%);
		background-position: 0 100%;
		background-repeat: repeat-x;
		background-size: 4px 2px;
		text-decoration: none;
		transition: .5s;
		@include regular-sans;
		cursor: pointer;
		font-size: 1.5rem;
		margin-bottom: 20px;

		@include tablet{
			font-size: 2rem;
		}

		&:hover {
			background-size: 4px 50px;
			color: white;
			transition: 0.5s;
		}
	}

	p{
		padding-bottom: 15px;
	}

	.left-col{
		width: 50%;
	}

	.right-col{
		background-image: url(../img/contact.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-position-y: 100%;
		width: 50%;
	}

}